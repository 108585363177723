<template>
	<div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center"> 
		<div class="container">
			<p class="logo"><a href="/"><img src="/static/img/flowe.png" class="imglogo"></a></p>
			<div>
				<div class="pagetitle2">
					<h2 class="text-center">Oops! Something went wrong</h2>
					<p class="text-center">We were unable to figure out what to do with that. Try refreshing the page or sign in again. If the error persists, please <a class="text-primary" href="https://help.flowe.io">let us know</a></p>
				</div>
				<div class="form-group responsepanel text-center">
					<a href="/sign-in" class="btn bt-xs btn-primary">Take me home</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript"></script>

<script>
	export default{
		name:'400error',

		mounted() { 
			$('#preloader').addClass('hide')
		}


	}
</script>