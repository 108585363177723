<template>
	<li> 
      	<router-link id="boardsroute" class = "side-icon-button" :to="item.to" 	>
        	<base-icon-label class ="overflow-string " :iconSize="item.iconSize" iconlabelafter ="text-light font-weight-bold" color="light" :icon ="item.icon" :labelafter ="item.name" /> 

      	</router-link>

    </li>
</template>
<style lang = "scss">
	
</style>
<script type="text/javascript"></script>
<script>
	export default{
		name: 'sidebaritem',
		props:{
			item:{
				type:Object,
				default: () => {},
			},
			isactive:{
				type:Boolean,
				default: () => {}
			},
		
		},

		computed:{

			//isactive(){
			//	return true
				//return this.item.active.indexOf(this.$route.meta.navindex) > -1
			//},

			listeners(){

		        return{
		          ...this.$listeners,
		          click: event =>
					this.$emit('click', event, this.item.name),
		        }
		    }

		}
	}
</script>