<template>
	<div v-if="shown" id="narbarmenu" class="border p-2"> 
    <div @click="isclicked">
      <slot/>      
    </div>
   </div>
 </template>
 <style lang ="scss">
  #narbarmenu___BV_modal_outer_{
    z-index: 1039 !important;
  }
  #narbarmenu{
    position: fixed;
    bottom: 50px;
    left: 0px;
    z-index: 99999999;
    height: 490px;
    width: 100%;
    background: #fff;
    /*
    display: block;
    .modal-dialog{
      bottom:-90%;
      transition: transform 0.25s ease-in;
      -webkit-transition: -webkit-transform 0.25s ease-in;
      -moz-transition: transform 0.25s ease-in;
      -o-transition: transform 0.25s ease-in;

      transition: transform 0.2s ease-out;
      -webkit-transition: -webkit-transform 0.2s ease-out;
      -moz-transition: transform 0.2s ease-out;
      -o-transition: transform 0.2s ease-out;
      transition: transform 0.2s ease-out;
    }*/
    
    
    &.show{
      .modal-dialog{
        top: calc(100% - 270px); 
        transform: translate(0, -469px);
        transform: translate(0, -469px);
      }
    }
  } 


</style>
 <script type="text/javascript"></script>

 <script>
  export default{
   name: 'popupmenu',
   props:{
    myobjectivesid:String,
    shown:Boolean,
    items:Array

  },
  data(){
   return{
    isshown:false,
  } 
},
watch:{
  shown : function( e ){
    this.isshown = e
  }
},
computed:{
},

methods:{
  isclicked(){
     this.$emit('onmodalhidden')    
  },
  modalshown(evt){

  },
  modalhidden(evt){
    let self =  this

    setTimeout(function(){
      self.$emit('onmodalhidden')
      self.isshown = false
    },250)
   
  }
}
}
</script>