<template>
		<ul class = "nav navi" :class="navclass">

			<li class ="nav-item" v-if="ismobile" >
				<div style="width:52px;">&nbsp;</div>
			</li>


			<template v-for = "(tab, index) in tabs"> 
				<li class ="nav-item"  :key = "index" :index= "index" :class="{'active': index == active}">


					<template v-if="tab.route!='/stakeholders' && tab.route!='/riskmanagement' && tab.route!='/masterplan'">
						
						<template v-if="tab.icon">
							
						</template>
						<template v-else>
							<a class="nav-link" v-on="listeners(tab)"  role="tab"> 
								{{tab.title}} 
							</a>
						</template>


					</template>
					<template v-else-if="tab.route=='/stakeholders' && ( tab.route=='/stakeholders' && cockpit.stakeholders && cockpit.stakeholders._id )"> 
						
						<template v-if="tab.icon">
							
						</template>
						<template v-else>
							<a class="nav-link" v-on="listeners(tab)"  role="tab"> 
								{{tab.title}} 
							</a>
						</template>

					</template>
					<template v-else-if="tab.route=='/riskmanagement' && ( tab.route=='/riskmanagement' && cockpit.risks )">
						
						<template v-if="tab.icon">
							
						</template>
						<template v-else>
							<a class="nav-link" v-on="listeners(tab)"  role="tab"> 
								{{tab.title}} 
							</a>
						</template>

					</template>

					<template v-else-if="tab.route=='/masterplan' && ( tab.route=='/masterplan' && ( cockpit.masterplanobjective && cockpit.masterplanobjective.isactive == true ) )">
						
						<template v-if="tab.icon">
							
						</template>
						<template v-else>
							<a class="nav-link" v-on="listeners(tab)"  role="tab" style="white-space: nowrap;"> 
								{{tab.title}} 
							</a>
						</template>

					</template>

					


				</li>

				<span v-if="showspaces" class ="spacer" :key = "index + 's'"></span>
			</template>
			
		</ul>
</template>
<style lang = "scss" scoped>
	@import'@/scss/abstracts/variable.scss';
	@import'@/scss/abstracts/mixin.scss';
	.nav{
		/* {{ cockpit.stakeholders }}/*/
		&.nav-topbar{
			margin-right: 10px;

			.spacer{
				width: 20px;
				height: 100%;
				// border-bottom: 1px solid $N200;
			}

			.nav-item{
				margin-bottom:-1px;

				&:not(.active){
				}

				.nav-link{
					font-size: 15px;
				    line-height: 14px;
				    padding: 0 0 4px 0;
				    margin: 0px 20px 0 0;   
				}
			}			
		}

		@include media-max-width($breakpoints-sm, $breakpoints-md){

			&.nav-topbar{
				margin-right: 0;
				flex-wrap: nowrap;
				// overflow-x: scroll;

				.spacer{
					display: none;
				}

				.nav-item{
					margin-bottom:-2px;
					
					.nav-link{
						text-transform: uppercase;
						font-size: 15px;
			    		min-width: 90px;
			    		padding: 15px 10px;
			    		margin: 0;
			    		&:not(.active){
							// padding-bottom: 11px;
						}
		    		}
		    	}
	    	}
		}

		&.nav-tabs{

			height: 100%;
			align-items: center;
			min-width: 1px;
			.nav-item{
				 display: inline;
				 text-align: center;
				 &.active{
					
					.nav-link{
						border-bottom: 2px solid $B500;
						color: $B500;
						font-weight: 700;
					}

				}
				.nav-link{
					border: none;
					
				}
			}
		}
	}
</style>
<script type="text/javascript">
</script>
<script>
	
	export default{
		name: "base-nav",
		props:{
			tabs:{
				type:Array,
				default: () => []
			},

			variant:{
				type: Array,
				default: () => ["tabs"]
			},

			active:{
				type:Number,
				default:0
			},
			hasspaces:{
				type:Boolean,
				default: false
			},
			stakeholdersenabled:{
				type:Boolean,
				default: false
			}


		},

		data(){
			return {
				activetab:{},
				randid:0, 
			}
		},
		watch:{
			'$store.state.screensize'(){

			}
		},

		methods:{
			listeners(tab){

				return{
					...this.$listeners,
					click: event =>
					this.$emit('click', tab),
				}
			}
		},
		computed:{

			routename(){

				return this.$route.name
			},

			ismobile(){
				return this.$store.state.isMobile
			},

			cockpit(){
				return this.$store.getters.GetCockpit
			},

			navclass(){
				var toreturn

				toreturn = `nav-${this.variant.join(' nav-')}`
				return toreturn

			},

			showspaces(){
				return this.hasspaces
			}

		}

	}
</script>