<template>
  <header class="sticky-top d-flex border-bottom" id="topnavbar" :class = "{'notabs':!tabsobject.length, 'no-go-back': !backarrow, 'simple': simple}">
    <div class ="header-navigations-bar mx-auto d-flex align-items-center" >
      <div class = "pagestopbar-pagesHeader">
        <div class = "TopbarPageHeaderStructure" v-if= "!simple">
         <div class = "TopbarPageHeaderStructure-leftElement" v-if="$store.state.screenType == 'mobile'">
          <div class = "TopbarPageHeaderStructure-leftElemen-top" v-if = "backarrow">
            <div class="TopbarSpacerL"></div>
            <a  @click ="goback" class ="d-flex back-arrow" style="font-size:20px; color:#4D515B !important; height: 100%;" >
              <i class="far fa-arrow-left align-self-center"></i>
            </a>
          </div>
          
            <!--  <div class = "pagestopbar-pagesMenu-buttom" v-if = "tabsobject.length"></div>
            

            <div class =" my-0 d-flex cockpit-icon  pl-2" :class = "{'cockpit-icon-hide': backarrow}" style="color:#4D515B !important;">
              <slot name= 'icon'/>
            </div>
          </div> -->  
          </div>

          <div class = "TopbarPageHeaderStructure-centerElement" :class ="{'w-100': hidedropdown}">
            <div class ="TopbarPageHeaderStructure-titleRow" :class="{'sidepadding':!backarrow}">
              <h3 id="cockpitviewtitle" class="TopbarPageHeaderStructure-title asalink m-0" 
              data-step = "1"  data-placement="bottom" @click.prevent="$emit('showdescription')">

              <slot name = 'title'/> 
              <!-- <span v-for ="key in index" :key= "key"  :class= "'show-'+key">{{key}}</span> -->
            </h3>

            <h3 class = " mb-0 ml-2" @click.prevent="$emit('showdescription')" v-if="hasdescription"> <slot name = 'description'/>  </h3>
            <slot name = "icons"></slot>
            <h3 class ="desrciption-bages mb-0 ml-2">  <slot name = "inactive"></slot></h3>
          </div>
              <!--   <div class = "TopbarPageHeaderStructure-tabsRow" v-if = "tabsobject.length">
              </div> -->

            </div>

            <!-- <div class = "TopbarPageHeaderStructure-rightElement">

            </div> -->



            <div class = "pagestopbar-pagesMenu" v-if="!hidedropdown">
              <!-- :class ="{'fa-ellipsis-v':columnnumber == 1, 'fa-ellipsis-h': columnnumber ==2 }" -->
              <div class = "pagestopbar-pagesMenu-top context-bar">
                <slot name = "righticons"/>
                <div class="align-self-center" >
                  <div class="btn-group ">
                   <a href="#" class="dropdown-toggle noicon menu-icon grouped-link" id ="topbarmenudropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-intro = "Board menu. Gives you access to board functions including the board settings." data-step = "2" data-position ="bottom">
                    <i class="dot-menu far fa-ellipsis-v"  aria-hidden="true" style = "font-weight: 400;"></i>
                  </a>
                  <div class="cockpit-menu dropdown-menu dropdown-menu-right" aria-labelledby="topbarmenudropdown" style ="background: #fffffff;">
                    <div class="cockpit_menu">                      
                        <slot name = "righticonsdropdown"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div class = "d-flex " v-else>
          <div class="TopbarSpacerL"></div>
          <h3  class="TopbarPageHeaderStructure-title asalink m-0">
            <slot name = 'title'/>
          </h3>
       </div>


      <div id="sectionsnavigation" class = " mb-3 TopbarPageHeaderStructure-buttom" v-if = "tabsobject.length">

        <div class = "TabNavigationBar d-flex"> 
          <base-nav :tabs = "tabsobject" @click="newtab" :active ="pagenow" :variant= "['tabs', 'topbar']" :stakeholdersenabled="stakeholdersenabled"></base-nav>
        </div>
      </div>

 
    </div>
  </div>
</header>
</template>

<style lang = "scss" scoped>
@import '@/scss/abstracts/variable.scss';
  // $value:360;
  // @while $value <= 1400 {
  //   $newvalue: $value * 1px;
  //   .show-#{$value}{
  //     display: none;
  //     @media( device-width: $newvalue ){
  //       display: inline-block;
  //     }
  //   }
  //   $value: $value + 1;
  // }




</style>
<script type="text/javascript"></script>
<script>
  import {bus} from '@/main'

  export default {
    name: 'TopBar',
    data(){
     return{
      prevScrollpos: window.pageYOffset,
      scrollCouldown: 0,
      tabnumber:0,
      contentBar: $('.context-bar'),
      dropdown: $('.dropdown-content'),
      more: $('span.grouped-link'),
      selected:''
    }
  },
  props:{
    stakeholdersenabled:Boolean,
    hasdescription:{
      type:Boolean,
      default: false
    },
    classes:String,
    hovertitle: String,
    simple:{
      type:Boolean,
      default:false
    },
    hidedropdown:{
      type:Boolean,
      default:false
    },
    tabsobject:{
      type: Array,
      default: ()=>[]
    },
    backarrow:{
      type:Boolean,
      default:false
    },
    pathback:{
      type:Object,
      default:() => {path:'/'}
    },
    activetopbar:Number
  },

  components:{
  },

  /*watch:{

   active: function(e){

    alert( this.$route.name )

   }

  },*/

  mounted(){


    //bus.$off('scrolltab', this.scrolltab )
    //bus.$on('scrolltab', this.scrolltab )

    let self = this

    setTimeout( function(){


      let leftscroll = 0

      if(  self.pagenow == 0  ){
        leftscroll = 1
      }
      if( self.pagenow == 1 ){  
        leftscroll = 70
      }
      if( self.pagenow == 2 ){
        leftscroll = 100
      }      
      if( self.pagenow == 3 ){  
        leftscroll = 200
      }

      self.scrolltab(leftscroll)


    }, 300)
    



    $(window).resize(this.update);
    this.update();

  },

  computed: {

    ismobile(){
                //return true
                return this.$store.state.isMobile
            },

    cockpit(){
        return this.$store.getters.GetCockpit
      },

    pagenow(){

      //

      var numeber = this.tabsobject.findIndex(x => x.name == this.$route.name)

      if( this.$route.name == 'teamsorganizationsbyid' ){
        numeber = 2
      }

      if( this.$route.name == 'boardreportsedit' ){
        numeber = 4
      }

      if( this.$route.name == 'boardreportsview' ){
        numeber = 4
      }

      
      return numeber
    }
  },

  methods:{

    scrolltab( leftscroll ){

      const sectionsnavigation = document.getElementById('sectionsnavigation');    

      const sectionsnavigationWidth = {}

      if(sectionsnavigation){

        sectionsnavigation.scrollWidth;      
        sectionsnavigation.scrollTo( leftscroll , 0);

      }

    },
    goback(){
      this.$router.push(this.pathback)
       bus.$emit('goback')
    },
    newtab(tab){

      let self = this

      let leftscroll = 0

      if( tab.name == 'boardoverview' ){
        leftscroll = 1
      }
      if( tab.name == 'boardmasterplan' ){  
        leftscroll = 40
      }
      if( tab.name == 'boardstakeholders' ){  
        leftscroll = 100
      }
      if( tab.name == 'boardriskmanagement'  ){
        leftscroll = 160
      }
      
      if( tab.name ==  'boardnewsletter'){  
        leftscroll = 220
      }

      if( tab.name ==  'boardreports'){  
        leftscroll = 220
      }

      if( tab.name == 'boardstatistics' ){  
        leftscroll = 280
      }

      this.scrolltab(leftscroll)

      
      setTimeout(function(){ self.$emit('opentabs', tab);}, 50);
      
    },

    update() {

      var offsettop = 0

      if( this.contentBar.offset() ){
        offsettop = this.contentBar.offset().top
      }

      let self = this

      var offsetTop = offsettop;
      this.dropdown.empty();
      this.contentBar.children().each(function(i, a) {
        if ($(a).offset().top > offsetTop) {
          if (a.innerHTML == selected) {
            a.classList.add("active");
            self.more.html(a.innerHTML).addClass('active');
          }
          dropdown.append($(a).clone());
        } else if (a.innerHTML == self.more.html()) {
          self.more.html('More').removeClass('active');
          return false;
        }
      });
    },
    opennewsletters(){
      // bus.$emit('opennewsletters')
    },

    openboards(){
     // bus.$emit('openboards') 
   },

   openstatistics(){
    bus.$emit('openstatistics')  
  },

  handleScroll222(){   
    var h = 0
    if($('#browser-banner').height()){
      var h = $('#browser-banner').height() +12*2
    }
   // console.log(h)
    var y =$('#topnavbar').height()+20 +h
    var currentScrollPos = window.pageYOffset;
    this.scrollCouldown++;

    if($(window).scrollTop() == 0) {
      this.scrollCouldown=0;
      window.jQuery("#topnavbar").css({'top':h});

    }else if($(window).scrollTop() + $(window).height() == $(document).height()) {
      window.jQuery("#topnavbar").css({'top':h});
    }
    if (this.prevScrollpos > currentScrollPos && this.scrollCouldown > 3  ) {
      window.jQuery("#topnavbar").css({'top':h});

    } else if (this.scrollCouldown > 3 && $(window).scrollTop() > y-20) {

      window.jQuery("#topnavbar").css({'top': '-'+y+'px'});
    }
    this.prevScrollpos = currentScrollPos;      

//    $('.dropdown-menu.show').removeClass('show')

    if(window.jQuery('.cockpit-menu').hasClass('show')){
      window.jQuery('.cockpit-menu').removeClass('show');
    }



  }
},
created () {
  window.addEventListener('scroll', this.handleScroll);
},
destroyed () {
  window.removeEventListener('scroll', this.handleScroll);
},

updated(){
  this.update()
}

}
</script>