var state = {
  userpool: "flowe-app-users",
  appdomain: "floweappusers.auth.eu-west-1.amazoncognito.com",
  clientid: "2lvmphbgdonovdgac461truu0s",
  redirecturi: "https://app.flowe.io/ssosignin",
  signouturi: "https://app.flowe.io/ssosignout",
  appurl: "https://app.flowe.io/"
}


export default {
  state: state
}
