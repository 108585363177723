<template>
	<div class = "content">
		<div v-if = "hasHeader" class =" logohedder w-100 d-flex">
			<slot name =  "header">
              <router-link class ="m-auto" :to="hasLink">
                  <img width="32" height="32" src="@/static/img/Logomark/FloweLogomark_Blue_S.png" class ="mr-3">
                  <img height="25" class ="my-auto" src="@/static/img/Logotype/FloweLogotype_Blue_M.png" >
              </router-link>
          	</slot>
        </div>

        <div v-if = "hasHeader" class ="diverder-middel "></div>   

        <div class="p-2" v-if="1==2">
        	
        	<base-icon-label :iconSize = "myorg.iconSize" :class = "buttomelementclasses" :icon ="myorg.icon" :labelafter ="myorg.labelafter"/>
        	{{ myorg.name }}
        </div>     

        <template v-for="(element,key ) in elements">
        	<slot name =  "orgnazations">

        		<template v-if="element.action && ( element.action !='subheader' && element.action !='divider' )">
        		<a v-if="element.action != 'myorg' " :key="'a_'+key" class="d-flex align-items-center statusopts menuitema">
	                  	<div class ="d-flex flex-row w-100 ">

	                  		<template v-if="1==1">

	                  			<div class="d-flex align-items-center p-1" @click="action(element.action, element.org )">
	                  			<img width="32" height="32" :src="element.org.logo" v-if="element.org && element.org.logo " class="rounded">
	        					<img width="32" height="32" class="rounded" :src = "'/static/img/names/' + element.org.name.charAt(0).toLowerCase()+'.png'" v-if="element.org && element.org.name && ( !element.org.logo || element.org.logo == '' )">
	        					<div width="32" height="32" class="rounded pl-2 pr-2 pb-2" v-if="element.icon"><i class="" :class="element.icon" ></i></div>
            					<span class="orgname ml-2 pb-2"> {{ element.org ? element.org.name : element.labelafter }} </span>
            					</div>


	                  		</template>
	                  		<template v-else>
	                  			<span class="p-2" v-if="element.icon==''" @click="action(element.action)">{{element.labelafter}}</span>
	                  			<base-icon-label v-else :iconSize = "element.iconSize"  @click="action(element.action)" :class="buttomelementclasses" :icon ="element.icon" :labelafter ="element.labelafter"/>
	                  		</template>
	                    	
	                  	</div>
	            </a>
	            <div v-else class ="d-flex flex-row w-100 no-click" :key="'div_'+key">

	                  		<template v-if="element.org">

	                  			<div class="d-flex align-items-center p-1" @click="action(element.action, element.org )">
	                  			<img width="32" height="32" :src = "element.org.logo" v-if="element.org.logo " class="rounded">
	        					<img width="32" height="32" class="rounded" :src = "'/static/img/names/' + element.org.name.charAt(0).toLowerCase()+'.png'" v-if="element.org.name && ( !element.org.logo || element.org.logo == '' )">
            					<span class="orgname ml-2"> {{element.org.name}} </span>
            					</div>


	                  		</template>
	                    	
	                  	</div>
	        	</template>

	            <div style="cursor: default;" v-if="element.action && element.action =='subheader'" :key = "element._id" class="d-flex align-items-center statusopts menuitema">
	                  	<div class ="d-flex flex-row w-100 m-2">{{ element.labelafter }}
	                  	</div>
	            </div>
	            <div v-if="element.action && element.action =='divider'" :key = "element._id">
	        		<div class ="diverder-middel"></div>
	            </div>
    		</slot>
    	</template>

  



	</div>
</template>
<style type="text/css">

	.no-click{

		cursor: pointer;
	}

	.menuitema:hover{
		background: #f5f6f8 !important;
	}
</style>

<script type="text/javascript"></script>
<script>
	import {bus} from '@/main'
	import Organizationsapi from '@/services/api/organizations'


	export default{
		name: 'orgnazationsSelector',
		props:{
			orgkey:{
				type:String,
				default: ""
			},
			noselect:{
				type:Boolean,
				default:false
			},
			hasHeader:{
				type: Boolean,
				default:true
			},
			hasLink:{
				type: String,
				default:"/"
			},
			userOrgItems:{
				type: Array,
				default: () => []
			},
			baseIconLabel:{
				type: Boolean,
				default: false
			},
			defaultElement:{
				type: Boolean,
				default: true,
			},

			buttomElement:{
				type: Array,
				default:() => []
			},
			buttomElementPos:{
				type:Number,
				default:0
			},
			defaultButtomElementClasses:{
				type:String,
				default: "clickable notfontwight"
			},
			buttomElementClasses:{
				type:String,
				default:""
			},
			hasProfileElement:{
				type:Boolean,
				default:true			
			}

		},

		data(){
			return{
				defaultelement:[
					
				],
				profileelement:[
					{
						iconSize:"",labelbefore:"", labelafter: "", icon:"",
						action: "divider"
					},
					{
						iconSize:"sm",labelbefore:"", labelafter: "My profile", icon:"",
						action: "openprofile"
					},
					{
						iconSize:"",labelbefore:"", labelafter: "", icon:"",
						action: "divider"
					},
					{
						iconSize:"sm",labelbefore:"", labelafter: "Sign out", icon:"far fa-sign-out",
						action: "signout"
					},
				],
				elements:[]
			}
		},

		watch:{
			
			userOrgItems(){
				this.$nextTick(() => this.setelements())
			}
		},

		mounted(){

			let self = this

			this.defaultelement = []

			/*
			this.defaultelement.push({
						iconSize:"sm",labelbefore:"", labelafter: null, icon:"",
						action: null
					})
			this.defaultelement.push({
						iconSize:"sm",labelbefore:"", labelafter: "View all organizations", icon:"",
						action: "showallorganizations"
					})

			this.defaultelement.push({
						iconSize:"sm",labelbefore:"", labelafter: null, icon:"",
						action: null
					})
					*/

			//if( this.ismobile ){


			this.defaultelement.push({
					iconSize:"sm",labelbefore:"", labelafter: "Settings", icon:"fal fa-cog",
					action: "showorgsettings"
				},
				{
					iconSize:"sm",labelbefore:"", labelafter: "Members", icon:"fal fa-user",
					action: "showorgmembers"
				},
				{
					iconSize:"sm",labelbefore:"", labelafter: "Teams", icon:"fal fa-users",
					action: "showorgteams"
				},
				{
					iconSize:"sm",labelbefore:"", labelafter: "", icon:"", action: "divider"
				},
				{
					iconSize:"sm",labelbefore:"Switch", labelafter: "Switch organization", icon:"",
					action: "subheader"
				})

				//this.defaultelement.push({iconSize:"sm",labelbefore:"", labelafter: "", icon:"", action: "divider"})

			//this.defaultelement = this.defaultelement.concat(this.userOrgItems)
			//console.log( this.userOrgItems , "ll")

			
				

				/**/
			//}

			/*this.defaultelement.push({
						iconSize:"sm",labelbefore:"", labelafter: null, icon:"",
						action: null
					})*/

			/*this.defaultelement.push({ 
						iconSize:"sm",labelbefore:"", labelafter: "Create new organization", icon:"far fa-plus",
						action: "createneworg"
					})*/



				this.$nextTick(() => this.setelements())
			

		},

		computed: {

			ismobile(){

				return this.$store.state.isMobile
			},

			buttomelementclasses(){
				let classes = this.defaultButtomElementClasses
				classes += `${this.buttomElementClasses}`
				classes += ``
				return classes
			},

			myorg(){

				let toreturn = {}

				if( this.userOrgItems && this.userOrgItems[0] ){
					
					let item = this.userOrgItems[0]

					toreturn = { iconSize:"sm",labelbefore:"", labelafter: item.name, icon:"", action: "myorg", org:item } //this.userOrgItems[0]
				}
				return toreturn

			},

			userOrgItemslist(){

				let self = this

				let element = []

				if( this.userOrgItems ){

					this.userOrgItems.forEach( function( item ){

						if( item.key != self.orgkey ){
							element.push( { org:item, iconSize:"sm",labelbefore:"", labelafter: item.name, icon:"", action: "switchorg" })
						}						

					})

				}

				return element
			},

			

			selectedorg(){
		        return  this.$store.state.organizations.currentorganization
		    },

		},

		methods:{//

			setelements(){

				let self = this
				let element = this.defaultelement

				this.elements = []

				if( this.userOrgItemslist ){
					element = element.concat(this.userOrgItemslist)
				}			

				/*{
						iconSize:"sm",labelbefore:"", labelafter: null, icon:"",
						action: "divider"
					},*/
				element.push({ 
						iconSize:"sm",labelbefore:"", labelafter: "View all organizations", icon:"",
						action: "showallorganizations"
					})

				

				element.push({
						iconSize:"sm",labelbefore:"", labelafter: null, icon:"",
						action: "divider"
					}, { 
						iconSize:"sm",labelbefore:"", labelafter: "Create new organization", icon:"far fa-plus",
						action: "createneworg"
					})

				if (this.hasProfileElement){
					element = element.concat(this.profileelement)
				}

				if( this.ismobile ){
					element.unshift(this.myorg)
				}

				

				this.elements = element
			},


			action(func, obj ){
				if (func == "showorgsettings"){
					this.showorgsettings('about')
				}else if (func == "showorgmembers"){
					this.showorgsettings('members')
				}else if (func == "showorgteams"){
					this.showorgsettings('teams')
				}else if (func == "createneworg"){
					this.createneworg()
				} else if (func == "showallorganizations"){
					this.showallorganizations()

				} else if (func == "openprofile"){
					this.openprofile()
				} else if (func == "signout"){
					this.signout()
				} else if (func == "switchorg"){
					if( obj ){
						this.selectorg(obj)
					}					
				} else {
					this.$emit(`${func}`)
				}
				
			},

			signout(){
				let self = this
				this.$store.dispatch('doSignOut')
			},

			showorgsettings( page){

				this.toorgsettings( this.selectedorg , page )

				//this.$root.$emit('bv::hide::modal', 'narbarmenu')
				//this.$emit('goorgsettings')
			},

			createneworg(){
				this.$root.$emit('bv::hide::modal', 'narbarmenu')
				this.$emit('showorgsettings')
			},

			openprofile(){
				this.$root.$emit('bv::hide::modal', 'narbarmenu')
				this.$router.push('/' + this.orgkey + '/profile')
			},

			showallorganizations(){
				//console.log( this.$route.name , "xxxxx")

				this.$root.$emit('bv::hide::modal', 'narbarmenu')

				if( this.$route.name != "organizationsoverview"){
					this.$router.push( '/' + this.orgkey + '/organizations' )
				}else{
					 bus.$emit('refreshorgpage')
				}
				
				
				
				
			},

			selectorg(org){


				//setTimeout( function(){

				//	$('#mainsidebar').width("60")
					//alert('')
				//}, 100)

				
				 //this.$root.$emit('bv::hide::modal', 'narbarmenu')

				let self = this

				
				//#enable this if you want to show on org select
				//this.$cookie.set('showorghelp', "yes");



			    if( this.selectedorg._id != org._id){			    	

			    	Organizationsapi.Details( localStorage.token, org.key ).then( function(response){

			    		//console.log( response.data.organization , "xxxxxxv")

			    		if( response.data.organization && response.data.organization.withaccess && response.data.organization.withaccess == true ){

			    			//alert('xx')

				    		self.$store.dispatch('setCurrentOrg',{ organization:org, token:localStorage.token }).then((response)=>{
				    			

				    				var str1 = 'Changed organization'
							        var str = 'You are now signed in to the ' + org.name + ' organization.'

						    		bus.$emit('craeteaToast', {content:str,title:str1, variant:'info',append:false, sup:''})

						    		//alert('')
						    		self.$router.push( '/'+org.key+'/boards?select' )
							        self.$emit('close')

				    			

				    		})

			    		}else{

			    			var str1 = 'Failed to change organization'
							var str = 'You do not have access to this organization.'

			    			bus.$emit('craeteaToast', {content:str,title:str1, variant:'warning',append:false, sup:'', callback:function(){
						    			location.reload()
						    }})
			    		}
			    		/*alert( response.myrole )

			    		if( response.myrole && response.myrole != 'DISABLE' ){

			    			
				        	

			    		}else{

			    			//var str1 = 'Permission denied'
				        	//var str = 'You are not authorized to access ' + org.name + ' organization.'

			    			//bus.$emit('craeteaToast', {content:str,title:str1, variant:'danger',append:false, sup:''})


			    		}*/

			    		


			        	

				       
			        })
			        

			        
			    }
			},

			toorgsettings(org, page){

			    this.$root.$emit('bv::hide::modal', 'narbarmenu')
			    this.$store.dispatch('setCurrentOrg',{ organization:org })
			    this.$router.push( '/'+ org.key +'/organizations/' + page )

			},
		}
	}
</script>