   <template>
    <aside id = "mainsidebar" class = "left-sidebar  m-0 p-0" :class="{'shorter':showbanner}" :style ="sidebarstyle" >

     <nav class = "sidebar-navbar" id="side-top-nav"  role="navigation" style = "">
       <div class = "main-icon-div" id="homelogoicon" >
        <div class = "side-img pb-2">

         <a :href=" orgname + '/boards' "
          class = "m-0 " id = "homelogo" tabindex="0">
          <div class="d-flex align-items-centers" style="height:36px"> 

           <div class =" d-flex " style="width:50px; min-width: 50px; margin-top: 1px;"> 
            <img src="@/static/img/o_logo-white.png"  class="flow-circle-logo" alt="O"> 
          </div>
          <span class = "side-icon-text mr-2 my-auto hide-text overflow-string" style="margin-left: 0px;">
            <img src="@/static/img/Logotype/FloweLogotype_White_M.png" style="height:24px; margin-left:5px;" alt="FLOWE">
          </span>
        </div>
      </a>

    </div>

    <div class="diverder-middel light"/>

    </div>

      <div class = "main-icon-div"  id="homelogo" >
        <div class = "side-img" >

          <div class="d-flex align-items-centers dropdown">
            
            <base-dropdown class = "nopadding " @action = "menuaction"  arialabelledby="sidebardropdown">
            <template v-slot:dropdown-btn>

              <base-org-logo class ="d-flex dropdown-toggle flex-row"  textClass ="text-light font-size-16 side-icon-text" data-display="static" id ="sidebardropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :hasName = "true" :organization = "organization">
                

              </base-org-logo>
            </template>


            <template v-slot:dropdown-items>
              <div style="width: 360px"> 
                <p class="pl-3" v-if="userorgitems.length == 0"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></p>
                <orgnazationsSelector :hasHeader =" false" :hasProfileElement="false" :baseIconLabel = "true" v-on = "listeners" :userOrgItems  ="userorgitems" :orgkey = "orgkey" buttomElementClasses ="dropdown-item"/>
              </div>


            </template>
          </base-dropdown>


        </div>

      </div>

    </div>

    <ul style="padding-left: 0 ">
     <template v-for ="(item,key) in itemstop">
       <sidebaritem :item ="item" :key="key" :isactive="isactive( item.id )"/>
     </template>
    </ul>

</nav>

<nav class = "bottom sidebar-navbar mb-2" id="side-bottom-nav"  role="navigation">


  <ul class ="mb-1" style="padding-left: 0  ">

     <template v-for ="(item,key) in itemsbottom">
       <sidebaritem :item ="item" :key="key" />
     </template>
    
    <li id ="accountdropdown"  data-toggle="tooltip" data-placement="left" data-trigger="hover" :data-original-title="userfirstname+' '+userlastname">
      <base-dropdown class ="nopadding" @action = "menuaction">
      <template v-slot:dropdown-btn>
        <div class = "main-icon-div" style=" height:52px;" id="miniprofilepicwrapper" ata-display="static" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
          <div class = "side-icon-div" style=" height:52px;" :class='{"is-active": $route.path.indexOf("/profile") > -1 }'>
            <a id = "myprofileroute" class = "m-0  d-inline d-flex" style=" height:52px;" >
              <div class="inner-side-icon-div d-flex" style=" height:52px;">
                <div class =" d-flex " style=" height:52px; width:50px; min-width: 50px;">
                  <base-avatar :imgsrc = "userpic" :name = "userfirstname" :width = "150"  id="profilepic" > </base-avatar>
                </div>
                <span class = "side-icon-text mr-2 my-auto hide-text">Account</span>
              </div>
            </a>
          </div>
        </div>
      </template>
      
      <template v-slot:dropdown-items>
       <router-link  class="dropdown-item" :to="{ path: orgname + '/profile' }"> <base-icon-label forcemd iconSize = "md" class ="clickable pl-2 "  icon ="" :labelafter ="'My profile' "/> </router-link >
       <div class ="diverder-middel"></div>
       <a class="dropdown-item" href="/sign-out" > <base-icon-label forcemd iconSize = "md" class ="clickable pl-2 "  icon ="far fa-sign-out" labelafter ="Sign out"/></a>
     </template>
   </base-dropdown>

 </li>
</ul>
</nav>
</aside>
</template>

<script type="text/javascript"></script>


<style lang="scss" >
@import '@/scss/abstracts/variable.scss';

#mainsidebar{

  .side-icon-button{
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer !important;
  }

  .company-logo{
    margin: 5px 5px;
    min-width: 40px;
    background: #ffffff;
  }

  ::v-deep{
      .dropdown-menu.show {
        min-width: 190px;
      }
  }

  .orgname{
    height:24px; 
    cursor: pointer;
  }

  #homelogoicon{
    margin: 11px 0;
    cursor: pointer;
  }

  #homelogo{
    margin: 12px 0;
    cursor: pointer;
  }

  ul{
    list-style-type:none;
  }

  .inner-side-icon-div.dropdown {
    overflow: visible !important;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  #organizationdropdownbutton {
    font-size: 15px;
    text-align: right;
    color: #fff;
    background: #2e477b;
  }

  .orgdropdown .dropdown-item {
    padding: 0.25rem;
  }

  .dropdown-text-toggle{
    &:hover{
      background:$B300;
    }
  }

  .dropdown-toggle{
    display: flex;
    width:200 - 10px;
    border-radius: 4px;
    padding-right: 8px;
    &:hover{
      background:$B300;
    }

    &::after{
      margin: auto !important;
      cursor: pointer;
      color:$NO;
    }
  }

}

</style>
<script>
  import orgsettings from '@/views/Layout/components/orgsettings'
  import orgnazationsSelector from './orgnazationsSelector'
  import sidebaritem from "@/components/navs/sidebaritem"
  //import Organizationsapi from '@/services/api/organizations'

  import {bus} from '@/main'
  
  export default {
    name: 'sideBar',
    props:{
      myobjectivesid:String,
      showbanner:Boolean,
    },
    data(){
     return{
      show:'none',
      neworgname:'',
      showneworg:true,
      orgs:[],
      orgsettingsopened:false,
      showorgkey:"",
      selectedorg:{},
      noselect:false,
      orglist:[],
      showdopdownmenu: false,
      //activeorganization:{}
    } 
  },
  wacth:{
  },
  computed:{

    routename(){
      return this.$route.name
    },
    sidebarstyle(){
      let data = {
        overflow: 'hidden'
      }

      if(this.showdopdownmenu == true){
        data.overflow = 'visible'
      }

      return data

    },
    isaccount(){
      return 'account'.indexOf(this.$route.meta.navindex) > -1
    },
    isorganizations(){
       return 'organizations'.indexOf(this.$route.meta.navindex) > -1
    },

    itemstop(){
      return[
      {name: 'Portfolios', to:{ path: this.orgname + '/portfolios' }, active:'portfolios', id:'myportfoliosroute', link:true, icon:'far fa-chart-scatter', iconSize:"sm"},
      {name: 'Boards', to:{ path: this.orgname + '/boards' }, active:'board', id:'myobjetivesroute', link:true, icon:'far fa-border-all', iconSize:"sm"},
      {name: 'Objectives', to:{ path: this.orgname + '/objectives' }, active:'objectives', id:'myobjetivesroute', link:true, icon:'far fa-dot-circle', iconSize:"sm"},]
    },

    itemsbottom(){
      return[
      /*  {name: 'Newsfeed', to:{ path: this.orgname + '/newsfeed' }, active:'newsfeed', id:'newsfeedroute', link:true, icon:'far fa-bell', iconSize:"sm"},*/
        {name: 'Help', to:{ path:this.orgname + '/help' }, active:'help', id:'helproute', link:true, icon:'far fa-question-circle', iconSize:"sm"}
      ]
    },
    listeners(){

        return{
          ...this.$listeners,
        }
      },
    userorgitems(){
      //let org = this.orglist
      //return org

      return this.$store.state.organizations.listorganizations

    },
    token(){
      return localStorage.token
    },

    userfirstname(){
      return this.$store.state.user.userInfo.firstname
    },
    userlastname(){
      return this.$store.state.user.userInfo.lastname
    },
    userpic(){
      return this.$store.state.user.userInfo.profilepic
    },
    organization(){
      return this.$store.state.organizations.currentorganization //this.activeorganization
      //this.$store.state.organizations.selectedorganizations
    },

    orgname(){
      return '/'+this.orgkey
    },

    orgkey(){
      return this.$route.params.orgname
    },
    organizationlogo(){
      return this.organization.logo
    }
  },
  mounted(){

    //this.selectedorg = this.organization

    //console.log( this.selectedorg, "zzz")

   /* $('#mainsidebar').mouseleave( function (){
      if($('#homelogo .dropdown-menu').hasClass( "show" ))
        $('#homelogo .dropdown-toggle').click()

      if($('#accountdropdown .dropdown-menu').hasClass( "show" ))
         $('#accountdropdown #miniprofilepicwrapper').click()
    } )

    */
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="tooltip"]').tooltip().data('bs.tooltip').config.template = '<div class="tooltip light" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      $('[data-toggle="tooltip"]').tooltip().data('bs.tooltip').config.delay = {show:500, hide:0}



        this.renderorgsmenu()



      },

      methods:{

        isactive( linkid ){

        let toreturn = false

        //prompt(linkid)

         if( linkid == "myteamsroute" && this.routename == "teamsorganizations" ){

            toreturn = true
         }
         if( linkid == "myportfoliosroute" && this.routename == "portfolio" ){

            toreturn = true
         }
         if( linkid == "myobjetivesroute" && this.routename == "boardsoverview" ){

            toreturn = true
         }
         if( linkid == "myobjetivesroute" && this.routename == "objectiveovervieworgs" ){

            toreturn = true
         }


         return false

        },
        menuaction(ev){

         // this.renderorgsmenu()

         let self = this

         this.$store.dispatch('fetchOrglist', {token: this.token}).then(( response ) => {

          if (ev == 'show'){
            self.showdopdownmenu = true
          } else if( ev == 'hide'){
            self.showdopdownmenu = false
          }


         })

          
        },
        renderorgsmenu(){

          var key = this.orgkey
          let self = this

          this.$store.dispatch('fetchOrglist', {token: this.token}).then(( response ) => {


            if( response.data.organizations ){
              self.orglist = response.data.organizations

              var organizations = response.data.organizations

              let defaultorganizations = {}
              
              if(key){


                defaultorganizations = organizations.find(x => x.key === key);
                if(!defaultorganizations && organizations[0]){
                 defaultorganizations = organizations[0]
                }

              }

              //self.activeorganization = defaultorganizations
              self.$store.dispatch('setCurrentOrg',{ organization:defaultorganizations })

            }

          })


        },

        showallorganizations(){

          this.$router.push( '/' + this.orgkey + '/organizations' )

        },
        toorgsettings(org){

          //alert('')
          //this.activeorganization = org
          this.$store.dispatch('setCurrentOrg',{ organization:org })
          this.$router.push( '/'+ org.key +'/organizations/about' )
          //this.$router.push({ name: 'aboutorganizations', params:{orgname:key}})
        },
        signout(){
          let self = this
          this.$store.dispatch('doSignOut')
        },

        showorgsettings( org ){

          this.orgsettingsopened = true
          this.showorgkey = org.key
          
        },
        opencreateorgmodal(){
         //3OmvRLgDp

          
          this.$emit('showorgsettings')
        },




    },

    components:{
      sidebaritem, orgsettings, orgnazationsSelector
    }
  }

</script>






