<template > 
  <div>




    <b-modal v-model = "lostaccesstoorg" size="sm-p" no-close-on-backdrop no-close-on-esc @hidden="assignnew">
      <template v-slot:modal-header>
        <h3 class="modal-title" v-if="lostaccesstoorgcreatednew">New organization created</h3>
        <h3 class="modal-title" v-else>No access to organization</h3>
        <a class = "close-modal dialog-close-button mr-2">
          <i class="fal fa-times fa-lg " @click="lostaccesstoorg = false" ></i>
        </a>
      </template>

      <span v-if="lostaccesstoorgcreatednew">
           You are not a member of the organization you are trying to access or any other organization in Flowe. In order to continue, a new organization has been created for you.
      </span>
      <span v-else>
          You are not a member of the organization you are trying to access and will be signed in to another organization instead.
      </span>
      
      

      <template v-slot:modal-footer="{ ok }" >
        <button class="btn btn-primary" @click="assignnew">OK</button>
      </template>
    </b-modal>


    <div id="main-navigationen">  
        <SideBar :myobjectivesid="myobjectives._id" @goorgsettings ="poporgsettings()" @showorgsettings ="showorgsettings = true" :showbanner="showbanner"></SideBar>
        <newsletterviewer :token ="token" :loggedinuser ="loggedinuser" ></newsletterviewer>
        <BottomBar :myobjectivesid="myobjectives._id" @goorgsettings ="poporgsettings()" @showorgsettings ="showorgsettings = true"></BottomBar>
        <orgsettings :shown = "showorgsettings" @onmodalhidden =" showorgsettings = false"/>
    </div>    

    <div class="main-view two" v-if="( !ssorequired || ( ssorequired && ( identityprovider == organizations.requireauth ) ) )">

      <p class="alert alert-warning" v-if="imstucked">I might be stuck... Reloading the page.</p>
      <router-view ></router-view>
    </div>

    <div v-else>

    <div class = "main-view p-3 alert alert-warning " v-if="organizations.idp" >



      <pre class="hide">{{ signInUserSession }} {{ identityprovider }} {{ organizations.requireauth }} </pre>

       <small class="m-1">You must be signed in with SSO to access this organization. Click here to <a href="#" @click="generatesigninlink()">sign in.</a></small>
      

    </div>


  </div>


  </div>
</template>

<script type="text/javascript"></script>

<script>
	import {SideBar, BottomBar, AppMain, orgsettings, helpContent} from './components'
	import {mainprofilemodal} from '@/components/profile'
  import introJs from 'intro.js/intro.js'
  import newsletterviewer from '@/views/newsletter/newsletterviewer'
  import Signinapi from '@/services/api/signin'
  import Userapi from '@/services/api/users'
  import Dashboardapi from '@/services/api/dashboard'
  import Newslettersapi from '@/services/api/newsletters'

  import newsfeed from '@/components/newsfeed/newsfeed'

  import {bus} from '@/main'

  import auth from '@/cognito.js';

  export default {

    name: 'Layout',
     props:{
      showbanner:Boolean
    },
    data(){
     return{
      lostaccesstoorgcreatednew: false,
      lostaccesstoorgneworg: "",
      lostaccesstoorg : false,
      showorgsettings: false,
      goorgsettings: false,
      myobjectives:{},
      newsletters:[],
      newsletter:{},
      showbottommenu: false,
      showhelpCenter: false,
      showmyprofile:false,
      showaboutFlowe: false,
      showhelpCenterBottom:false,
      shownewsfeed: false,
      deviseDesktop:true,
      showintro: true,
      openguidemodal: false,
      introguideroute: '',
      timeleft : 20,
      emailchanged : false,
      sessionisdyingsomewhere: false,
      window_focus : true,
      counterinterval : null,
      sessionisdead:false,
      tabisactive:false,
      openguide: false,
      printr : {},
      excludepage : false,
      pagenow : "",
      pageloaded: false,
      imstucked:false
          }
  },
  components: {
   SideBar,
   BottomBar,
   AppMain,
   newsletterviewer,
   orgsettings
 },

 computed: {

    signInUserSession(){

      var authuser = auth.auth.getSignInUserSession()

      return authuser
    },


      identityprovider(){
        var authuser = auth.auth.getSignInUserSession()
        //return authuser.idToken

        var toreturn

        for( var i in authuser.idToken.payload ){

          if( i == "custom:group" ){

            toreturn = authuser.idToken.payload[i]
            break;
          }
        }

        return toreturn
      },


  expirein(){

    if( this.printr.accessToken ){

      var dte = new Date(0)
      dte.setUTCSeconds(this.printr.accessToken.payload.exp);

      //var now = new Date()

      //var to = new Date()

      //to.setUTCSeconds(now - dte);
      //this.printr.accessToken.payload.exp
      return dte
    }else{
      return "--"
    }

  },


  samldata(){

    return this.$store.getters.saml ? this.$store.getters.saml : {}
  },
  jwtinfo(){

    return this.samldata
  },
  loggedinuser(){
    return this.$store.getters.user
  },
  token(){
    return localStorage.token
  },
  newslettercokpitid(){
    return this.$route.params.cid
  },
  showhints(){
        return this.$store.state.showhints
      },
  orgname(){
    if( this.$route.params.orgname ){
      return this.$route.params.orgname
    }else{
      return ""
    }
  },

  organizations(){
        return this.$store.state.organizations.selectedorganizations

      },

  ssorequired(){


    this.excludepage = false
    if( this.$route.name == 'profile' || this.$route.name == 'organizationsoverview'){
      this.excludepage = true
    }

    if( this.organizations.requireauth && this.excludepage == false ){
      return this.organizations.requireauth != '' ? true : false
    }else{
      return false
    }
          
  },
  isuserauth(){

            return this.samldata
          }

},


created(){


      this.excludepage = false
      if( this.$route.name == 'profile' || this.$route.name == 'organizationsoverview'){
        this.excludepage = true
      }

      //console.log('hihihi')

      console.log('layout_index_created')

      },

    mounted(){


      let self = this

      this.loadDashboard() 

      console.log('layout_index_mounted')

       $('#preloader').addClass('hide')

       var t = setTimeout( function(){
        $('#preloader').addClass('hide')

        //self.$cookie.set('organizationkey', '');
        //self.imstucked = true
        //location.href='/'
       }, 15000)

        

        


        if( self.$route.query.nid && self.$route.query.nid != "" && self.$route.query.page == 'newsletter'){

            var cid = self.$route.params.cid

            Newslettersapi.Getnewsletterbyid( self.$route.query.nid , self.token).then(function(response){       
                if( response.data.newsletter.ispublished ){
                    bus.$emit('onopennewsletterforviewing',response.data.newsletter._id,response.data.cockpit._id)
                }
            })
        }
        this.$nextTick(function(){
          $('.modal').modal('hide');
          $('.modal-backdrop').remove()
          $('body').removeClass('modal-open')
        })
        bus.$on('poporgsettings',this.poporgsettings)  
    },
    watch:{

      organizations( e, ev ){

        /*console.log( location.href ) 

        var parts = location.href.split("/")
        var newurl = []
        for( var i in parts ){

          if( i == 3){
            newurl.push( this.organizations.key )
          }else{
            newurl.push( parts[i])
          }
        }*/

        

        if( this.organizations.key && ( this.$route.params.orgname != this.organizations.key )  ){
          //this.$router.push("/" + this.organizations.key + "/boards")

          var parts = location.href.split("/")
          var newurl = []
          for( var i in parts ){

            if( i == 3){
              newurl.push( this.organizations.key )
            }else{
              newurl.push( parts[i])
            }
          }

          //location.href=newurl.join("/")
          //this.$router.push( newurl.join("/") )
        }

  //console.log( this.$route.params.orgname , "ccccccccccc" )
   //console.log( this.organizations.key , "ccccccccccc")

      },

    $route (to, from) {
      let self = this


      if( to.path.indexOf("/boards") > -1 && to.path != from.path){

        this.loadDashboard() 
      }

      this.excludepage = false
      if( to.name == 'profile' ){
        this.excludepage = true
      }


    },

    isuserauth( to,from ){
            //alert('userauthchanged')
          },

    

    openguidemodal: function(e){
      // console.log(e)
    }
  },

  methods:{

    reloaddata(){


      this.$cookie.set('organizationkey', '');
      location.href = "/";


    },

    generatesigninlink(){


      Signinapi.Generateidpurl( this.organizations.idp ).then( function(response){

        location.href=response.data.idp.idp

       // console.log(  , "xxxxxxx")
      })


    },

    poporgsettings(){
      this.showorgsettings = true
    },



    startguide(route, cockpitshowhints){
      if(this.showintro || cockpitshowhints ){
        // this.openguidemodal = true
      }

      
    },

    assignnew(){


      this.lostaccesstoorg = false

     // bus.$emit('craeteaToast', {content:"You do not have access to this organization, and have been signed in to the " +  this.lostaccesstoorgneworg.name + " organization.",title:"Changed organization", variant:'info',append:true, sup:''})

      this.$store.dispatch('setOrgmanually', this.lostaccesstoorgneworg ).then(( response ) => {

        Signinapi.Purgeorginfo( this.token ).then( function(response){
          location.href="/"
        })

             
      }).catch( function(){

          location.href="/"
      })







    },

   loadDashboard(callback){

    let self = this   

    bus.$emit('ondashboardloaded', [] )


    Dashboardapi.Mydashboarditems( this.token, this.orgname )
    .then( function(response){

      self.pageloaded = true

      if( response.data.ok ){
    
        self.myobjectives = response.data.mymeasuresoverview
        self.showintro = response.showhints
        self.openguide = response.showhints

       // alert('')

        setTimeout( function(){
          bus.$emit('ondashboardloaded', response.data )
        }, 1000)
        


        //bus.$emit('ondashboardloaded', response.data.mymeasuresoverview )
      }else{       
////

        if( response.data.orgerror == "disableduser" || response.data.orgerror == "orgdisabled" ){

          if( response.data.suggestorg ){
            self.lostaccesstoorgneworg = response.data.suggestorg
           // self.lostaccesstoorg = true     


            if( response.data.suggestorg.isnew == true )  {
              self.lostaccesstoorgcreatednew = true
            }

            self.assignnew()
          }


        }else{
//          self.$router.push("/403")
        }

      }

    })
    .catch(error => console.log(error))
    .finally( () => {

      self.status = 'idle'
      self.$nextTick(function(){
      
    })

    //do everything
    //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
  })
    if(callback){
      callback()
    }

  },

  showhelpCenterBottommodal(){
    this.showhelpCenterBottom=true
    this.touchdragup()
      //window.addEventListener(‘resize’, this.getWindowHeight);



    },
  
  touchdragup(){
   this.$nextTick(function() {
    var draggable = $('.dropup-menu-modal .modal-header');
    var ts;

    draggable.bind('touchstart', function (e){
     ts = e.originalEvent.touches[0].clientY;

   });

    draggable.on('touchmove', function(e){
      var te = e.originalEvent.changedTouches[0].clientY;
      if(ts-te > 60 ||  ts-te < -60) {

      }

      if(ts-te > 60){

       $('.dropup-menu-modal .modal-dialog').css({'max-height':'100%'});
       $('.dropup-menu-modal .modal-content').css('height','100vh')
       $('.dropup-menu-modal .modal-body').css('overflow-y','scroll')

     }else if(ts-te < -60){
      $('.dropup-menu-modal .modal-content').css('height','auto')
      $('.dropup-menu-modal .modal-dialog').css({'max-height':'50%'});
      $('.dropup-menu-modal .modal-body').css('overflow-y','hidden')
    }

  })
  })
 },


 latestindicator(){

  var tabs = $( '.indicatortab', $('.hasindicators')[0] )
  return tabs[ tabs.length - 1 ]

},



hintposition(){

  var hints = []
  if( this.$route.path.indexOf("/boards") > -1 ){
    if(this.deviseDesktop){
          hints=[
           {
            element: document.querySelector('#homelogoicon'),
            intro: "Click here to go to the dashboard - an overview of all the cockpits you have access to.",
            position: 'bottom'
          }
          ]
        }
    hints.push(
  
   

    {
      element: document.querySelector('#newsfeedicon'),
      intro: 'A list of all published report, from all cockpits that you have access to.',
      position: 'left'
    },
    {
      element: document.querySelector('#helpicon'),
      intro: 'Click to see all your objectives on one page.',
      position: 'bottom'
    },

    {
      element: document.querySelector('#miniprofilepicwrapper'),
      intro: "Access your user profile.",
      position: 'left'
    }

   
    )

  }else{

    hints =
    [
    {
      element: document.querySelector('#cockpitviewtitle'),
      intro: "Board title. Click to see a detailed description of the board.",
      position: 'top-left'
    },
    {
      element: document.querySelector('#topnavbar .dropdown-toggle'),
      intro: "Board menu. Gives you access to board functions including the board settings.",
      position: 'middle-middle '
    },
    {
      element: document.querySelector('#cockpitview .card'),
      intro: "This is an objective. Click the title to see its description.",
      position: 'top-left'
    },
    {
      element: document.querySelector('#cockpitview .card .dropdown-toggle'),
      intro: "Objective menu. This gives access to objective specific information and configuration.",
      position: 'middle-middle'
    },
    {
      element: this.latestindicator(),
      intro: "Latest status submitted for an objective, click on the status icon to see more information.",
      position: 'middle-middle'
    },
    {
      element: document.querySelector('#cockpitview .objectiveadd'),
      intro: "Click here to add a new objective to your board.",
      position: 'top-left'
    },
    ]

  } 
  return hints
},

hideorshowhints(){
  let self = this
  

  this.introsteps = this.hintposition()
  var hints = []

  $.each( this.introsteps, function( i,o ){
    hints.push( { element: o.element, hint: o.intro, hintPosition: o.position} )
  })

  if( this.showhints == false ){
    introJs().removeHints();
  }else{


    var intro = introJs(); 
    intro.setOptions({'buttonClass':'btn btn-primary' });
    intro.setOptions({hints:hints})

    intro.onhintsadded(function() {
                    // console.log('all hints added');
                  });


    intro.showHints()

    intro.onhintclick(function() {
                    console.log('hint clicked', this);
                  });
    intro.onhintclose(function(stepId){

      intro.removeHint(stepId)

      if($('.introjs-hints').children().length == 0){
        introJs().removeHints();
        this.$store.commit('SET_SHOWHINTS',false)
      }

    });
  }
  // this.showhelpCenter=false

},
emitfoundt(){
                // console.log('emit')
              },

              openbottommen(){
                this.showbottommenu=true;
                this.touchdragup()
              },

              loadNewsfeed(){
                
                let self = this
                // console.log('user id',this.loggedinuser)
                alert('x')
                Newslettersapi.Getfeed( this.$store.state.user.userInfo._id)
                .then( function(response){

                  if( response.data.ok ){
                    self.newsletters = response.data.newsletters
                    //console.log(response.data.newsletters)
                  }

                })
                .catch(error => console.log(error))
                .finally( () => {
                 
                  this.shownewsfeed = true
                //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
              })

              },

              loadNewsletter(newsletterid, autherid){
                let self = this
                Newslettersapi.Getnewsletter({'_nid':newsletterid,'_uid':autherid}).then(
                  function(response){

                    if( response.data.ok ){
                      // console.log( response.data )
                      self.newsletter= response.data.newsletter
                    }

                  })
                .catch(error => console.log(error))
                .finally( () => {

                })
              },



              noScroll() {
                window.scrollTo(0, 0);
              },

              disableScroll() {
                let self = this 
                if (window.addEventListener) {
            // add listener to disable scroll
            window.addEventListener('scroll', self.noScroll); 
            window.addEventListener('DOMMouseScroll', self.preventDefault, false);  
            window.onwheel = self.preventDefault; // modern standard
            window.onmousewheel = document.onmousewheel = self.preventDefault; // older browsers, IE
            window.ontouchmove  = self.preventDefault; // mobile
            document.onkeydown  = self.preventDefaultForScrollKeys;

          
        }
      },

      enableScroll() {
        if (window.removeEventListener){
          let self = this 
          window.removeEventListener('scroll', self.noScroll);
          window.removeEventListener('DOMMouseScroll', self.preventDefault, false);
          window.onmousewheel = document.onmousewheel = null; 
          window.onwheel = null; 
          window.ontouchmove = null;  
          document.onkeydown = null;

        }
      },

      preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault){
          e.preventDefault();

        }

      },

      preventDefaultForScrollKeys(e) {
        if (this.keys[e.keyCode]) {
          preventDefault(e);
          return false;
        }
      },


    },
    updated(){

    }
  }


</script>

<style>
#page-content{
  width: 100%;
}
</style>